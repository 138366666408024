<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="posturl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {
      title:'Asuransi',
      posturl:'/assurance',
      fields :[
        { key:'code', label:'Kode', placeholder:'Kode Asuransi', type: 'input',rules:'required' },
        { key:'name', label:'Nama', placeholder:'Nama Asuransi', type: 'input',rules:'required' },
        { key:'address', label:'Alamat', placeholder:'Alamat',  type: 'autocomplate',rules:'required' },
        { type:'map', label:''},
        { key:'city', label:'Kota', placeholder:'Masukkan Kota', type: 'autocomplate', indexValue:'name', objectView:'data', dataurl:'/city?search=' },
        { key:'postal_code', label:'Kode pos', placeholder:'Kode Pos',  type: 'input',rules:'' },
        { key:'email', label:'Email', placeholder:'Email',  type: 'input',rules:'required|email' },
        { key:'phone', label:'Telephone', placeholder:'Telephone',  type: 'input',rules:'' },
        { key:'hr'},
        { key:'cp_name', label:'Contact Person', placeholder:'Nama Contact Person', type: 'input',rules:'required' },
        { key:'cp_number', label:'Telephone Contact Person', placeholder:'Telephone', type: 'input',rules:'' },
        { key:'cp_email', label:'Email Contact Person', placeholder:'Email', type: 'input',rules:'' },
        { key:'hr'},
        { key:'register_date', label:'Tanggal Register',  type: 'date',rules:'', val:'now' }
      ],
    }
  },
}
</script>